import { useEffect } from 'react';
import { useStateMachine } from "little-state-machine";
import updateAction from "../updateAction";
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default function BookConfirmation(props) {

    const { state } = useStateMachine({ updateAction });
    let navigate = useNavigate();
    const sp = `${process.env.REACT_APP_LOCAL_SERVER}`;

    useEffect(() => {

        async function fetchData() {
            // save the booking
            //console.log(JSON.stringify({ state }));
            const requestOptions = {
                method: 'POST',
                mode: "cors",
                body: JSON.stringify({ state })
            };
            fetch(sp + '/save_appt.php', requestOptions)
                .then(async response => {
                    //console.log(response);
                    //props.finishFnc();
                })
                .catch(error => {
                    alert(error);
                    //setPageError(true);
                    //console.error('There was an error!', error);
                });
        }

        //console.log(props, state)

        if (props.tabIndex === 5 && state.booking.paid === true)
            fetchData()
    }, [props.tabIndex, sp, state])

    function goTo(page) {
        navigate(page);
    }

    return (
        <div>
            { state.booking.paid === true ?
            <>
                <h2 style={{margin: "10px"}}>Thank you for making the following booking...</h2>
                <table style={{fontSize: "18px", margin: "20px 10px 10px 10px"}}>
                    <tbody>
                        <tr >
                            <td>Location:</td>
                            <td>{state.booking.practice_name}</td>
                        </tr>
                        <tr >
                            <td>Reason:</td>
                            <td>{state.booking.reason_desc}</td>
                        </tr>
                        <tr>
                            <td>Clinician:</td>
                            <td>{state.clinician_name}</td>
                        </tr>
                        <tr>
                            <td>Date &amp; Time:</td>
                            <td>{dayjs('' + state.selected_date + '' + state.selected_time, "YYYYMMDDHHmm").format('dddd Do MMMM YYYY @ h:mma')}</td>
                        </tr>
                    </tbody>
                </table>
                <div style={{fontSize: "18px", margin: "20px 10px 10px 10px"}}>We've sent these details to you by email.</div>
                <div className="space-between-mobile">
                <button type="button" className="booking-btns" onClick={() => goTo(`/home`)}>Home</button>
                <button type="button" className="booking-btns" style={{width: "180px"}} onClick={() => goTo(`/appointments`)}>View Appointment</button>
                </div>
            </>
            :
            <div>There was an issue with making the booking, no payment has been taken. Please try again.</div>
            }
        </div>
    )
}