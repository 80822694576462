import { useRef, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { BsPersonFill } from "react-icons/bs";
import { BiCalendar, BiCalendarPlus } from "react-icons/bi";
import { RiPhoneFill } from "react-icons/ri";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ContactUsDialog from "./ContactUsDialog";

import { useStateMachine } from "little-state-machine";

export default function Layout(props) {
    const contactUsDialogRef = useRef();
    const dropdownRef = useRef();
    const popoutDropdownRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    let navigate = useNavigate(); const open = Boolean(anchorEl);
    const [editDetails, setEditDetails] = useState(true)
    const { state } = useStateMachine();

    useEffect(() => {

        async function fetchData() {
            fetch(`${process.env.REACT_APP_LOCAL_SERVER}/config.php?action=setup`)
                .then(async (response) => {
                    const setupResult = await response.json();
                    //console.log(setupResult);

                    if (setupResult.result === "Success") {
                        if (setupResult.settings.edit_details === '0')
                            setEditDetails(false);
                    }
                })
                .catch((error) => {
                    //setPageError(true);
                    console.error('There was an error!', error);
                });
        }

        fetchData();
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const contactUsDialogOpen = () => {
        contactUsDialogRef.current.show();
    };

    function goTo(page) {
        navigate(page);
    }

    return (
        <div>
            <AppBar position="static" className="app-appbar">
                <Toolbar>
                    <Typography className="header-title" >{state.practice.practice_name}</Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        ref={dropdownRef}
                        onClick={handleClick}
                        startIcon={<KeyboardArrowDown/>} 
                        className='header-menu'    
                    >
                        <p className="layout-dropdown">{state.patient.patient_name}</p>
                    </Button>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        ref={popoutDropdownRef}
                        getcontentanchorel={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => goTo(`/home`)}><HomeIcon/> &nbsp;&nbsp;&nbsp; Home</MenuItem>
                        <hr></hr>
                        { editDetails &&
                        <MenuItem onClick={() => goTo(`/details`)}><BsPersonFill size="25"/> &nbsp;&nbsp;&nbsp; My Details</MenuItem>
                        }
                        <MenuItem onClick={() => goTo(`/appointments`)}><BiCalendar size="25"/> &nbsp;&nbsp;&nbsp; View Appointments</MenuItem>
                        <MenuItem onClick={() => goTo(`/book`)}><BiCalendarPlus size="25"/> &nbsp;&nbsp;&nbsp; Book Appointment</MenuItem>
                        <MenuItem onClick={contactUsDialogOpen}><RiPhoneFill size="25"/> &nbsp;&nbsp;&nbsp; Contact Us</MenuItem>
                        <hr></hr>
                        <MenuItem onClick={() => goTo(`/logout`)}><ExitToAppIcon/> &nbsp;&nbsp;&nbsp; Sign Out</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <ContactUsDialog ref={contactUsDialogRef}></ContactUsDialog>
            <div className="app-content">{props.children}</div>
        </div>
    );
};