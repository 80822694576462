import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createStore, useStateMachine } from "little-state-machine";
import clearAction from "./clearAction";

import Layout from "./layout";

export default function Logout(props) {
    let navigate = useNavigate();
    const sp = `${process.env.REACT_APP_LOCAL_SERVER}`;
    const { actions, state } = useStateMachine({ clearAction });

    useEffect(() => {
        async function doLogOut() {
            const requestOptions = {
                method: "POST",
                mode: "cors",
                body: JSON.stringify({ login_code: state.login_code }),
              };
              fetch(sp + "/logout.php", requestOptions)
                .then(async (response) => {
                    navigate(`/`);
                })
                .catch((error) => {
                  //setPageError(true);
                  //console.error('There was an error!', error);
                });
        }

        doLogOut();
    }, [navigate, sp])

    return (
        <Layout>
            <span>You have been logged out.</span>
        </Layout>
    )
}