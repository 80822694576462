import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Grid';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

import "./registerPage.css";

export default function Register(props) {
  let navigate = useNavigate();
  const sp = `${process.env.REACT_APP_LOCAL_SERVER}`;
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [chosenPractice, setChosenPractice] = useState();
  const [practiceList, setPracticeList] = useState([]);
  const [titleList, setTitleList] = useState([]);
  const [showExisting, setShowExisting] = useState(false);
  const [newComplete, setNewComplete] = useState(false);

  const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  const months = [
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
  ];

  function generateArrayOfYears() {
    var max = new Date().getFullYear();
    var min = max - 120;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  const years = generateArrayOfYears();

  // choose the practice if more than one
  // load the practice config for the selected
  // enter patient details
  useEffect(() => {
    async function fetchData() {
      const requestOptions = {
        method: "GET",
        mode: "cors",
      };
      fetch(`${sp}/config.php?action=practices`, requestOptions)
        .then(async (response) => {
          const setupResult = await response.json();
          //console.log(setupResult);

          setPracticeList(setupResult.practices);
          if (setupResult.practices.length === 1) {
            setValue("practice_id", setupResult.practices[0].practice_id);
            setChosenPractice(setupResult.practices[0].practice_id);
          }
        })
        .catch((error) => {
          //setPageError(true);
          //console.error('There was an error!', error);
        });
    }

    fetchData();
  }, [setValue, sp]);

  useEffect(() => {
    //console.log("load titles");
    async function loadTitles() {
      const requestOptions = {
        method: "GET",
        mode: "cors",
      };
      fetch(`${sp}/config.php?action=titles&practice_id=${chosenPractice}`, requestOptions)
        .then(async (response) => {
          const titeResult = await response.json();
          setTitleList(titeResult.titles);
        })
        .catch((error) => {
          //setPageError(true);
          //console.error('There was an error!', error);
        });
    }

    if (chosenPractice) loadTitles();
  }, [chosenPractice, sp]);

  function chooseTitle(event, index) {
    setValue("title", event.itemData.title_text);
    //handleSubmit(onSubmit)();
  }

  function choosePractice(event, index) {
    setValue("practice_id", event.itemData.practice_id);
    setChosenPractice(event.itemData.practice_id);
    //handleSubmit(onSubmit)();
  }

  function chooseDOBd(event, index) {
    //console.log(event);
    setValue("dobd", event.itemData.value);
    //handleSubmit(onSubmit)();
  }

  function chooseDOBm(event, index) {
    //console.log(event);
    setValue("dobm", event.itemData.value);
    //handleSubmit(onSubmit)();
  }

  function chooseDOBy(event, index) {
    //console.log(event);
    setValue("doby", event.itemData.value);
    //handleSubmit(onSubmit)();
  }

  const onSubmit = async (data) => {
    const requestOptions = {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ login_code: "_new", details: data }),
    };
    fetch(`${sp}/details.php`, requestOptions)
      .then(async (response) => {
        const saveResult = await response.json();
        if (saveResult.result === "Existing") setShowExisting(true);
        else if (saveResult.result === "Success") {
          setShowExisting(false);
          setNewComplete(true);
        } else if (saveResult.result === "Error") {
          alert("There was an error");
        }
      })
      .catch((error) => {
        //setPageError(true);
        //console.error('There was an error!', error);
      });
  };

  return (
    <>
      <div className={window.innerWidth >= 568 ? "" : ""}>
        <div className="register-page-container">
          <div className="register-container">
            <h2 style={{ textAlign: "center" }}>Patient Sign Up</h2>
            <form>
              {newComplete === false ? (
                <>
                  {practiceList.length > 1 && (
                    <>
                      <p style={{ fontSize: "18px" }}>There is more than one practice in our group, which one would you like to register at?</p>

                      <DropDownListComponent
                        name="practice_id"
                        select={choosePractice}
                        dataSource={practiceList}
                        cssClass="e-outline"
                        {...register("practice_id")}
                        fields={{ text: "practice_name", value: "practice_id" }}
                      />
                      {chosenPractice === undefined ? (
                        <button
                          className="register-cancel-btn"
                          style={{ marginTop: "20px" }}
                          type="button"
                          onClick={() => navigate('/')}>
                          Cancel
                        </button>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {chosenPractice && (
                    <>
                      <hr />
                      <Grid container className="form-container">
                        <Grid item xs={12} sm={3}>
                          Title
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <DropDownListComponent
                            name="title"
                            select={chooseTitle}
                            dataSource={titleList}
                            cssClass="e-outline"
                            {...register("title")}
                            fields={{ text: "title_text", value: "title_text" }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          Forenames
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <TextBoxComponent
                            type="text"
                            htmlAttributes={{ autoCapitalize: "words" }}
                            cssClass="e-outline"
                            {...register("forenames", { required: true, maxLength: 40, onChange: v => {
                              if (!v.value.length) return v.value;
                              v.target.value = v.value.charAt(0).toUpperCase() + v.value.slice(1);
                            } })}
                          />
                          {errors.forenames && <p className="error">⚠ Forename is required.</p>}
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          Surname
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <TextBoxComponent
                            type="text"
                            htmlAttributes={{ autoCapitalize: "off" }}
                            cssClass="e-outline"
                            {...register("surname", {
                              required: true, maxLength: 40, onChange: v => {
                                if (!v.value.length) return v.value;
                                v.target.value = v.value.charAt(0).toUpperCase() + v.value.slice(1);
                              }
                            })}
                          />
                          {errors.surname && <p className="error">⚠ Surname is required.</p>}
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          Date of Birth
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <DropDownListComponent
                            name="dobd"
                            placeholder="Day"
                            cssClass="e-outline"
                            select={chooseDOBd}
                            dataSource={days}
                            {...register("dobd", { required: true })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <DropDownListComponent
                            name="dobm"
                            placeholder="Month"
                            cssClass="e-outline"
                            select={chooseDOBm}
                            dataSource={months}
                            fields={{ text: "text", value: "value" }}
                            {...register("dobm", { required: true })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <DropDownListComponent
                            name="doby"
                            cssClass="e-outline"
                            placeholder="Year"
                            select={chooseDOBy}
                            dataSource={years}
                            {...register("doby", { required: true })}
                          />
                        </Grid>
                        {(errors.doby || errors.dobm || errors.dobd) && <p className="error">⚠ A valid DOB is required.</p>}
                      </Grid>

                      <hr />
                      <Grid container className="form-container">
                        <Grid item xs={12} sm={3}>
                          Email Address
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <TextBoxComponent
                            type="email"
                            htmlAttributes={{ autoCapitalize: "off" }}
                            cssClass="e-outline"
                            {...register("home_email", {
                              required: true,
                              pattern:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                          />
                          {errors.home_email && <p className="error">⚠ Email address is missing, or is not valid.</p>}
                        </Grid>
                      </Grid>
                      <Grid container className="form-container">
                        <Grid item xs={12} sm={3}>
                          Mobile Number
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <TextBoxComponent
                            type="email"
                            htmlAttributes={{ autoCapitalize: "off" }}
                            cssClass="e-outline"
                            {...register("mobile_phone", {
                              required: true,
                              pattern: /[0-9]+/,
                            })}
                          />
                          {errors.mobile_phone && <p className="error">⚠ Mobile number is missing, or is not valid.</p>}
                        </Grid>
                      </Grid>
                      <div className="register-buttons">
                        <button className="register-cancel-btn" type="button" onClick={() => handleSubmit(onSubmit)()}>
                          Confirm
                        </button>
                        <button className="register-cancel-btn" type="button" onClick={() => navigate('/')}>
                          Cancel
                        </button>
                      </div>
                      {/* </Row> */}
                      {showExisting && (
                        <div>
                          <hr />
                          A patient is already registered with these details.<br /><Link to="/">You can log in here...</Link>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div>
                    <hr></hr>
                    <p style={{ fontSize: "18px" }}>Thank you for registering with the patient portal, you may now sign in!</p>
                    <button
                      className="register-cancel-btn"
                      style={{ marginTop: "10px" }}
                      type="button"
                      onClick={() => navigate('/')}>
                      Sign In
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
